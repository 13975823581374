import { Box, CircularProgress } from '@mui/material';
import React from 'react';

export default function CubeProgress() {
    return (
        <Box 
            display="flex"
            justifyContent="center"
            alignItems="center"
            >
            <CircularProgress />
        </Box>
    )
};