import React, { useEffect, useState } from "react";
import { CubeAppBar } from "./components/CubeAppBar";
import { Box, Button, Container, Typography, Stepper, Step, StepLabel, StepContent, Paper, Stack, TextField } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { ref, runTransaction } from "firebase/database";
import { auth, db } from "./services/firebase";
import { useAuthState } from "react-firebase-hooks/auth";

export default function Setup() {
    const [user, loading, error] = useAuthState(auth);
    const [mac, setMac] = useState("");
    const [localIp, setLocalIp] = useState("");
    const [connected, setConnected] = useState("");
    const [firebaseConnected, setFirebaseConnected] = useState("");
    const [ssid, setSsid] = useState("");
    const [key, setKey] = useState("");
    const [ssidError, setSsidError] = useState(false);
    const [keyError, setKeyError] = useState(false);
    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = () => {
        if (activeStep == 0) {
            if (!ssid || ssid == "") {
                setSsidError(true);
                return;
            }

            if (!key || key == "") {
                setKeyError(true);
                return;
            }

            ConfigureWiFi().then(() => {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            });
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const steps = [
        {
            label: 'Setup WiFi',
            description: `Your cube needs to be connected to your home WiFi to function properly. Please enter your WiFi information to proceed.`,
        }
      ];
      

    const GetStatus = async () => {
        try {
            const response = await fetch("https://setup.cube.wilsn.io/api/status");
            const json = await response.json();

            setMac(json.mac);
            setLocalIp(json.localIp);
            setConnected(json.connected);
            setFirebaseConnected(json.firebase);
        } catch {
            console.log("Error while trying to get status");
        }
    };

    const ConfigureWiFi = async () => {
        if (!user) return;

        const response = await fetch(`https://setup.cube.wilsn.io/api/wifi?ssid=${ssid}&key=${key}&owner=${user.email}&uid=${user.uid}`);
    };

    useEffect(() => {
        setInterval(GetStatus, 15000);
    }, []);

    return (
        <>
            <CubeAppBar />
            {!firebaseConnected || firebaseConnected == "" || firebaseConnected == "false" ? (
                <Container maxWidth="sm" sx={{
                    paddingTop: 5
                }}>
                    <Box sx={{ maxWidth: 400 }}>
                        <Stepper activeStep={activeStep} orientation="vertical">
                            {steps.map((step, index) => (
                            <Step key={step.label}>
                                <StepLabel
                                    optional={
                                        index === 2 ? (
                                        <Typography variant="caption">Last step</Typography>
                                        ) : null
                                    }
                                    >
                                    {step.label}
                                </StepLabel>
                                <StepContent>
                                    <Typography>{step.description}</Typography>
                                    <Box sx={{ mb: 2, paddingTop: 5}}>
                                        <div>
                                            {step.label == "Setup WiFi" ? (
                                                <Stack spacing={2}>
                                                    <TextField
                                                        id="ssid"
                                                        label="SSID"
                                                        style={{ width: 250 }}
                                                        placeholder="MyCoolWiFiName"
                                                        onChange={(event) => setSsid(event.target.value)}
                                                        error={ssidError}
                                                    />
                                                    <TextField
                                                        id="key"
                                                        label="Key"
                                                        style={{ width: 250 }}
                                                        placeholder="WiFiKeyHere123!"
                                                        onChange={(event) => setKey(event.target.value)}
                                                        error={keyError}
                                                    />
                                                </Stack>
                                            ) : (<></>)}
                                            <Button
                                                variant="contained"
                                                onClick={handleNext}
                                                sx={{ mt: 1, mr: 1 }}
                                            >
                                                {index === steps.length - 1 ? 'Finish' : 'Continue'}
                                            </Button>
                                            <Button
                                                disabled={index === 0}
                                                onClick={handleBack}
                                                sx={{ mt: 1, mr: 1 }}
                                            >
                                                Back
                                            </Button>
                                        </div>
                                    </Box>
                                </StepContent>
                            </Step>
                            ))}
                        </Stepper>
                        {activeStep === steps.length && (
                            <Paper square elevation={0} sx={{ p: 3 }}>
                                <Typography>All steps completed - you&apos;re finished</Typography>
                            </Paper>
                        )}
                    </Box>
                </Container>
            ) : (
                <Container maxWidth="sm" sx={{
                    paddingTop: 5
                }}>
                    <Box 
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        >
                        <Typography>Searching for cube to configure...</Typography>
                    </Box>
                </Container>
            )}
        </>
    )
}