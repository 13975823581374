import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../services/firebase";
import AppBar from '@mui/material/AppBar';
import { Autocomplete, Box, Button, Checkbox, Dialog, Fab, IconButton, Slide, Stack, TextField, Toolbar, Typography } from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';
import { TransitionProps } from "@mui/material/transitions";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Colorful from "@uiw/react-color-colorful";
import { rgbaToHsva, hsvaToRgba, HsvaColor, RgbaColor } from "@uiw/color-convert";
import { child, get, push, ref, runTransaction, set } from "firebase/database";
import { useState } from "react";
import { User } from "firebase/auth";
import dayjs from "dayjs";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { setFlagsFromString } from "v8";

const Transition = React.forwardRef(
    function Transition(props: TransitionProps & { children: React.ReactElement; }, ref: React.Ref<unknown>,) {
        return <Slide direction="up" ref={ref} {...props} />;
    }
);

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

type Friend = {
    displayName: string,
    photoUrl: string,
    uid: string
};

export default function CubeSendColor() {
    const [user, loading, error] = useAuthState(auth);
    const [open, setOpen] = useState(false);
    const [friends, setFriends] = useState<Friend[]>([]);
    const [selectedFriends, setSelectedFriends] = useState<Friend[]>([]);
    const [message, setMessage] = useState<string>("");
    const [hsva, setHsva] = useState({ h: 355, s: 100, v: 100, a: 1 });
    const [getFriends, {data: getFriendsData, error: getFriendsError, loading: getFriendsLoading}] = useLazyQuery(gql`
        query Query {
            Friends {
                displayName
                photoUrl
                uid
            }
        }
    `);
    const [sendMessage, {data: sendMessageData, error: sendMessageError, loading: sendMessageLoading}] = useMutation(gql`
        mutation Send ($friends: [String!]!, $r: Int!, $g: Int!, $b: Int!, $message: String!) {
            sendMessage(friends: $friends, r: $r, g: $g, b: $b, message: $message) {
                id
                message {
                    from
                    to
                    color {
                        r
                        g
                        b
                    }
                    timestamp
                    message
                }
            }
        }
    `);

    const handleClickOpen = async () => {
        await GetFriends();
        setOpen(true);
    };

    const handleClose = async () => {
        setOpen(false);
    };

    const GetFriends = async () => {
        const friends = await getFriends();

        setFriends(friends.data.Friends);
    };

    const ProcessSendColor = async () => {
        if (!user) return;

        // Send the message
        const color: RgbaColor = hsvaToRgba(hsva);

        await sendMessage({
            variables: {
                friends: selectedFriends.map((f) => {
                    return f.uid;
                }),
                r: color.r,
                g: color.g,
                b: color.b,
                message: message
            }
        })

        // Close the dialog
        await handleClose();
    };

    return (
        <>
            <Fab 
                color="primary" 
                aria-label="add" 
                sx={{
                    position: 'fixed',
                    bottom: 25,
                    right: 25,
                }}
                onClick={handleClickOpen}
            >
                <SendIcon />
            </Fab>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Send Color
                        </Typography>
                        <Button autoFocus color="inherit" onClick={ProcessSendColor}>
                            SEND
                        </Button>
                    </Toolbar>
                </AppBar>
                <Box 
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        paddingTop: 5
                    }}
                >
                    <Stack spacing={2}>
                        <Autocomplete
                            multiple
                            id="friends"
                            options={friends}
                            disableCloseOnSelect
                            getOptionLabel={(option) => option.displayName}
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {option.displayName}
                                </li>
                            )}
                            style={{ width: 250 }}
                            renderInput={(params) => (
                                <TextField {...params} label="To" placeholder="Add Friends" />
                            )}
                            onChange={(event, value) => setSelectedFriends(value)}
                        />
                        <Box 
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Colorful 
                                color={hsva}
                                disableAlpha={true}
                                onChange={(color) => {setHsva(color.hsva)}}
                                style={{
                                    width: 250
                                }}
                            />
                        </Box>
                        <TextField
                            id="message"
                            label="Message"
                            multiline
                            rows={4}
                            style={{ width: 250 }}
                            placeholder="Optional message"
                            onChange={(event) => setMessage(event.target.value)}
                        />
                    </Stack>
                </Box>
            </Dialog>
        </>
    )
};