// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getAuth, GoogleAuthProvider, indexedDBLocalPersistence } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, onMessage } from "firebase/messaging";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBDHoBnh7Dmih_UB2EJqSyqwl66Znrk6Ns",
  authDomain: "friend-cube-bae89.firebaseapp.com",
  databaseURL: "https://friend-cube-bae89-default-rtdb.firebaseio.com",
  projectId: "friend-cube-bae89",
  storageBucket: "friend-cube-bae89.appspot.com",
  messagingSenderId: "734967453270",
  appId: "1:734967453270:web:165e838004217b49a98df7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getDatabase(app);
const mdb = getDatabase(app);
const auth = getAuth(app);
const messaging = getMessaging(app);
const analytics = getAnalytics(app);
const googleProvider = new GoogleAuthProvider();

db.auth = auth; // For writing, transactions, and single reads
mdb.auth = auth; // For continuous monitoring

auth.setPersistence(indexedDBLocalPersistence);

export {
  db,
  mdb,
  auth,
  messaging,
  googleProvider,
  analytics
}