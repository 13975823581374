import React, { useEffect, useReducer, useState } from "react";
import Button from '@mui/material/Button';
import { db, auth } from "./services/firebase";
import { ref, runTransaction } from "firebase/database";
import { useAuthState } from "react-firebase-hooks/auth";
import { CubeAppBar } from "./components/CubeAppBar";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Stack from "@mui/material/Stack";
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { rgbaToHex } from "@uiw/color-convert";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import relativeTime from 'dayjs/plugin/relativeTime';
import { gql, useLazyQuery } from "@apollo/client";
import Grid from "@mui/material/Unstable_Grid2";
import { styled } from '@mui/material/styles';
import { AvatarGroup, Badge, Divider, Fab, Hidden, Tooltip } from "@mui/material";
import Paper from '@mui/material/Paper';
import CubeSendColor from "./components/CubeSendColor";
import RefreshIcon from '@mui/icons-material/Refresh';

dayjs.extend(relativeTime);

type Cube = {
    mac: string
}

type Color = {
    r: string,
    g: string,
    b: string
}

type Friend = {
    displayName: string,
    photoUrl: string,
    uid: string
}

type Message = {
    color: Color,
    to: [string],
    from: string,
    timestamp: string,
    message: string,
    replies: [Reply]
}

type Reply = {
    from: string,
    timestamp: string,
    message: string
}

interface IProps {
    cubeColor: string;
}

const StyledBadge = styled(Badge)((props: IProps) => ({
    '& .MuiBadge-badge': {
      backgroundColor: `${props.cubeColor}`,
      color: `${props.cubeColor}`,
      boxShadow: `0 0 0 2px ${props.cubeColor}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: 'ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }));

export default function Messages() {
    const navigate = useNavigate();
    const [user, loading, error] = useAuthState(auth);
    const [cube, setCube] = useState<Cube | undefined>();
    const [friends, setFriends] = useState<Friend[] | undefined>([]);
    const [receivedMessages, setReceivedMessages] = useState<Message[]>([]);
    const [getData, {data}] = useLazyQuery(gql`
        query Query {
            Cubes {
                mac
            }
            Friends {
                displayName
                photoUrl
                uid
            }
            ReceivedMessages {
                color {
                    r
                    g
                    b
                }
                to
                from
                message
                replies {
                    from
                    timestamp
                    message
                }
                timestamp
            }
            SentMessages {
                color {
                    r
                    g
                    b
                }
                to
                from
                message
                replies {
                    from
                    timestamp
                    message
                }
                timestamp
            }
        }
    `);

    async function LoadData() {
        const result = await getData({
            pollInterval: 5000
        });

        setCube(result.data.Cubes[0].mac);
        setFriends([...result.data.Friends]);
        setReceivedMessages([...result.data.ReceivedMessages]);
    }

    useEffect(() => {
        if (loading) return;
    
        if (!user) navigate("/login");

        LoadData();
    }, [loading]);

    const GetFriendDetails = (uid: string): Friend => {
        if (friends) {
            // Look for user in friend list
            for (var friend of friends) {
                if (friend.uid == uid)
                    return friend;
            }
        }

        // User was not found so return unknown
        return {
            displayName: "Unknown",
            photoUrl: "",
            uid: ""
        }
    }

    const RenderMessage = (from: string, to: [string], color: any, message: string, timestamp: number) => {
        if (!user) return;
        if (!friends) return;

        const fromUser = GetFriendDetails(from);
        const colorHex = rgbaToHex({r: color.r, g: color.g, b: color.b, a: 1});

        return (
            <>
                <Paper elevation={1} style={{padding: 10}}>
                    <Grid container spacing={0} columns={12}>
                        <Grid xs={2} display="flex" justifyContent="left" alignItems="top">
                            <Tooltip title={fromUser?.displayName}>
                                <StyledBadge
                                    overlap="circular"
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    variant="dot"
                                    cubeColor={colorHex}
                                >
                                    <Avatar alt={fromUser?.displayName} src={fromUser?.photoUrl} />
                                </StyledBadge>
                            </Tooltip>
                        </Grid>
                        <Grid container xs={10} display="flex" justifyContent="left" alignItems="top">
                            <Grid xs={10}>
                                <Typography variant={"caption"} display={"block"} alignItems="flex-start">
                                    {fromUser.displayName}
                                </Typography>
                            </Grid>
                            <Grid xs={10}>
                                <Typography variant={"caption"} display={"block"}>
                                    {dayjs.unix(+timestamp).fromNow()}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container spacing={0} columns={12}>
                        <AvatarGroup max={6}>
                            {to.map((uid) => (
                                <Tooltip title={GetFriendDetails(uid).displayName}>
                                    <Avatar sx={{ width: 24, height: 24 }} alt={GetFriendDetails(uid).displayName} src={GetFriendDetails(uid).photoUrl} />
                                </Tooltip>
                            ))}
                        </AvatarGroup>
                    </Grid>
                    {(message && message != "") ? (
                        <Grid container spacing={0} columns={12} sx={{
                            marginTop: 1
                        }}>
                            <Typography variant={"body1"}>
                                {message}
                            </Typography>
                        </Grid>
                    ) : (<></>)}
                    <Divider sx={{
                        marginTop: 1,
                        marginBottom: 1
                    }} flexItem />
                    <Grid container spacing={0} columns={12}>
                        <Button variant="text" size={"small"} onClick={() => SetColor(color)}>Recube</Button>
                    </Grid>
                </Paper>
            </>
        )
    };

    const SetColor = async (color: any) => {
        if (!cube) return;

        const dbRef = ref(db, `/cubes/${cube}/color`);
        await runTransaction(dbRef, (c) => {
            if (c) {
                c.r = color.r;
                c.g = color.g;
                c.b = color.b;
            }

            return c;
        });
    };

    return (
        <>
            <CubeAppBar />
            {user && !loading ? (
                <Container maxWidth="sm" sx={{
                    paddingTop: 2
                }}>
                    <Stack spacing={2}>
                        <Box 
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        >
                            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                {data && data.ReceivedMessages && (data.ReceivedMessages as Message[]).map(e => (
                                    <ListItem alignItems="flex-start">
                                            <ListItemText
                                                primary={RenderMessage(e.from, e.to, e.color, e.message, +e.timestamp)}
                                            />
                                    </ListItem>
                                ))}
                            </List>
                        </Box>
                    </Stack>
                </Container>
            ) : (
                <></>
            )}
            <CubeSendColor />
        </>
    )
}