import React from 'react';
import ReactDOM from 'react-dom/client';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import theme from './themes/default.theme';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Home from "./Home";
import Messages from "./Messages";
import Login from './Login';
import About from './About';
import Setup from './Setup';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { ApolloClient, InMemoryCache, ApolloProvider, gql, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { auth } from "./services/firebase";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/messages",
    element: <Messages />
  },
  {
    path: "/login",
    element: <Login />
  }, 
  {
    path: "/setup",
    element: <Setup />
  },
  {
    path: "/about",
    element: <About />
  }
]);

// Apollo Client
const gqlUri = "https://us-central1-friend-cube-bae89.cloudfunctions.net/graphql";
const httpLink = createHttpLink({
  uri: gqlUri
});
const authLink = setContext(async (_, { headers }) => {
  if (!auth.currentUser) return;
  
  return auth
          .currentUser
          .getIdToken(true)
          .then((token) => {
            return {
              headers: {
                ...headers,
                Authorization: token ? `Bearer ${token}` : ""
              }
            }
          });
});
const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink)
});

root.render(
  <ApolloProvider client={client}>
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <RouterProvider router={router} />
      </ThemeProvider>
    </React.StrictMode>
  </ApolloProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
//serviceWorkerRegistration.unregister();
serviceWorkerRegistration.register({
  onUpdate: registration => {
    if (registration && registration.waiting) {
      registration.waiting.postMessage({ type: "SKIP_WAITING" });
    }
  },
  onSuccess: registration => {
    
  }
});