import { Box, Button, ButtonGroup, Stack, Typography } from "@mui/material";
import Colorful from "@uiw/react-color-colorful";
import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db, mdb } from "../services/firebase";
import { usePersistedState } from "../util/PersistentState";
import { rgbaToHsva, hsvaToRgba, HsvaColor, RgbaColor } from "@uiw/color-convert";
import { DataSnapshot, child, get, onValue, ref, runTransaction, update } from "firebase/database";
import { gql, useLazyQuery, useMutation } from "@apollo/client";

type ColorChangeEvent = {
    hsva: HsvaColor
};

const CubeController = () => {
    const [user, loading, error] = useAuthState(auth);
    const [cube, setCube] = useState(undefined);
    const [hsva, setHsva] = usePersistedState("hsva", { h: 0, s: 0, v: 68, a: 1 });
    const [haveLock, setHaveLock] = useState(false);
    const [lockOwner, setLockOwner] = useState("");
    const [getCube, {loading: getCubeLoading, error: getCubeError, data: getCubeData}] = useLazyQuery(gql`
        query Query {
            Cubes {
                mac
            }
        }
    `);
    const [updateCubeStatus, { data: updateCubeStatusData, loading: updateCubeStatusLoading, error: updateCubeStatusError}] = useMutation(gql`
        mutation Update ($cube: String!, $status: String!) {
            updateCubeStatus(cube: $cube, status: $status) {
                result
            }
        }
    `);

    useEffect(() => {
        if (!loading)
            GetCube();
    }, [loading]);

    useEffect(() => {
        if (!cube) return;

        Monitor();
    }, [cube]);

    const TurnOff = async () => {
        if (!cube || !haveLock) return;

        updateCubeStatus({
            variables: {
                cube: cube,
                status: "offline"
            }
        });
    };

    const TurnOn = async () => {
        if (!cube || !haveLock) return;

        updateCubeStatus({
            variables: {
                cube: cube,
                status: "online"
            }
        });
    };

    const SetColor = async (color: RgbaColor) => {
        if (!cube || !haveLock) return;

        const dbRef = ref(db, `/cubes/${cube}/color`);
        await runTransaction(dbRef, (c) => {
            if (c) {
                c.r = color.r;
                c.g = color.g;
                c.b = color.b;
                c.a = 1;
            }

            return c;
        });
    };

    const ColorChange = async (color: ColorChangeEvent) => {
        if (!user || !cube || !haveLock) return;

        // Set local cache of color
        setHsva(color.hsva);

        // Set database color
        SetColor(hsvaToRgba(color.hsva));
    };
    
    const Random = async () => {
        if (!cube || !haveLock) return;

        // Generate random color
        const color = {
            r: Math.floor(Math.random() * 255),
            g: Math.floor(Math.random() * 255),
            b: Math.floor(Math.random() * 255),
            a: 1
        };

        // Set the local cache of color
        setHsva(rgbaToHsva(color));

        // Set database color
        SetColor(color);
    };

    const GetCube = async () => {
        if (!user) return;

        const query = await getCube();

        setCube(query.data.Cubes[0].mac);
    };

    const Monitor = async () => {
        if (!user || !cube) return;

        const lockRef = ref(mdb, `cubes/${cube}/lock`);
        onValue(lockRef, LockMonitor.bind(haveLock));

        const colorRef = ref(mdb, `cubes/${cube}/color`);
        onValue(colorRef, ColorMonitor.bind(haveLock));
    };

    const LockMonitor = async (s: DataSnapshot) => {
        if (!user || !cube) return;

        const v = s.val();

        // Set the lock owner
        setLockOwner(v);

        // Check to see if we are the lock owner
        if (v == user.displayName) {
            // Have the lock
            setHaveLock(true);
        } else {
            // No longer have the lock
            setHaveLock(false);
        }
    };

    const ColorMonitor = async (s: DataSnapshot) => {
        if (!user || !cube) return;

        const v = s.val();
        setHsva(rgbaToHsva(v));
    };

    const TakeLock = async () => {
        if (!user || !cube) return;

        const dbRef = ref(db, `/cubes/${cube}`);
        await runTransaction(dbRef, (c) => {
            if (c) {
                c.lock = user.displayName;
            }

            return c;
        });
    };

    return (
        <>
        {cube ? (
            <Stack spacing={2}>
                <Box 
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                >
                    <ButtonGroup>
                        <Button variant="contained" onClick={Random} disabled={!haveLock}>Random</Button>
                        <Button variant="contained" onClick={TurnOff} disabled={!haveLock}>Turn Off</Button>
                        <Button variant="contained" onClick={TurnOn} disabled={!haveLock}>Turn On</Button>
                    </ButtonGroup>
                </Box>
                <Box 
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Colorful 
                        color={hsva}
                        disableAlpha={true}
                        onChange={ColorChange}
                        style={{
                            width: 280
                        }}
                        />
                </Box>
                {!haveLock ? (
                    <>
                        <Box 
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Typography>{lockOwner} is currently using the cube.</Typography>
                            
                        </Box>
                        <Box 
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Button onClick={TakeLock}>Take Control</Button>
                        </Box>
                    </>
                ) : (<></>)}
                
            </Stack>
        ) : (
            <Box 
                display="flex"
                justifyContent="center"
                alignItems="center"
                >
                    <Typography>Looking for cube...</Typography>
            </Box>
        )}
        </>
    );
};

export default CubeController;