import { Box, Container, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { CubeAppBar } from "./components/CubeAppBar";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "./services/firebase";
import { gql, useApolloClient, useLazyQuery } from "@apollo/client";
import CubeSendColor from "./components/CubeSendColor";

export default function About() {
    const [user, loading, error] = useAuthState(auth);
    const client = useApolloClient();

    useEffect(() => {
        if (!user) return;

        user
            .getIdToken()
            .then((token) => console.log(token));
    }, [user]);

    return (
        <>
            <CubeAppBar />
            <Container maxWidth="sm" sx={{
                paddingTop: 5
            }}>
                <Box 
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    >
                    <Typography>Made for friends somewhere around December 2023.. ish.</Typography>
                </Box>
            </Container>
            <CubeSendColor />
        </>
    )
};