import React, { useEffect } from "react";
import { auth } from "./services/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { CubeAppBar } from "./components/CubeAppBar";
import Container from '@mui/material/Container';
import CubeProgress from "./components/CubeProgress";
import CubeController from "./components/CubeController";
import CubeSendColor from "./components/CubeSendColor";

export default function Home() {
  const navigate = useNavigate();
  const [user, loading, error] = useAuthState(auth);

  useEffect(() => {
    if (loading) return;

    if (!user) navigate("/login");
  }, [loading]);

  return (
    <>
      <CubeAppBar />
      <Container maxWidth="sm" sx={{
        paddingTop: 5
      }}>
        {user && !loading ? (
          <>
            <CubeController />
          </>
        ) : (
          <CubeProgress />
        )}
      </Container>
      <CubeSendColor />
    </>
  );
}