import React, { useEffect, useState } from "react";
import { db, auth, googleProvider } from "./services/firebase";
import { get, update, set, onValue, query, ref, runTransaction } from "firebase/database";
import { signInWithPopup } from "firebase/auth";
import { Box, Button } from "@mui/material";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { CubeAppBar } from "./components/CubeAppBar";
import { gql, useLazyQuery, useMutation } from "@apollo/client";

export default function Login() {
    const navigate = useNavigate();
    const [user, loading, error] = useAuthState(auth);
    const [ensureUser, {}] = useMutation(gql`
        mutation EnsureUser($uid: String!, $email: String!, $photoUrl: String!, $displayName: String!) {
            ensureUser(uid: $uid, email: $email, photoUrl: $photoUrl, displayName: $displayName) {
                result
            }
        }
    `);

    const Login = async () => {
        try {
            // Do the login
            const result = await signInWithPopup(auth, googleProvider);

            // If login was successful then ensure user exists in RTDB
            if (result.user) {
                const u = result.user;

                await ensureUser({
                    variables: {
                        uid: u.uid,
                        email: u.email,
                        photoUrl: u.photoURL,
                        displayName: u.displayName
                    }
                })

                navigate("/");
            }
        } catch (error) {
            console.error("Error signing in with Google: ", error);
        }
    };
        
    return (
        <>
            <CubeAppBar />
            <Box 
                display="flex"
                justifyContent="center"
                alignItems="center"
                >
                <Button onClick={Login}>Login with Google</Button>
            </Box>
        </>
    );
};