import * as React from "react";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import HistoryIcon from '@mui/icons-material/History';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import InfoIcon from '@mui/icons-material/Info';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from "react-router-dom";
import { Avatar, Button } from "@mui/material";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, messaging } from "../services/firebase";
import PhonelinkSetupIcon from '@mui/icons-material/PhonelinkSetup';
import CubeSendColor from "./CubeSendColor";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { getToken, onMessage } from "firebase/messaging";
import NotificationAddIcon from '@mui/icons-material/NotificationAdd';
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

type Anchor = 'top' | 'left' | 'bottom' | 'right';

export const CubeAppBar = () => {
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false
  });
  const [setPush, {}] = useMutation(gql`
    mutation SetPush($uid: String!, $token: String!) {
      setPush(uid: $uid, token: $token) {
        result
      }
    }
  `);

  const ToggleDrawer = 
    (anchor: Anchor, open: boolean) => 
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({...state, [anchor]: open});
  };

  const Logout = async () => {
    try {
        await auth.signOut();
        navigate("/login");
    } catch (error) {
        console.error("Error logging out of Google: ", error);
    }
  }

  const EnableNotifications = async () => {
    if (!user) return;

    console.log("Requesting permission for push notifications...");
    const permission = await Notification.requestPermission();

    if (permission === "granted")
      console.log("Notification permission granted.");

      const token = await getToken(messaging, {
        vapidKey: "BNOdHAhrpbi_kSdoyKvwK30WcsXmqh9nX3kKM5DRmkCS3ceNDvLUeD7Rv2wSsK6tb_Qdib8lQW8MY2GUqUas9Os"
      });

      const result = await setPush({
        variables: {
          uid: user.uid,
          token: token
        }
      });
  };

  onMessage(messaging, (payload) => {
    console.log(
      "Push message (foreground): ",
      payload
    );

    if (!payload || !payload.data || !payload.data.message) return;

    toast(payload.data.message);
  });

  return (
    <>
      <Drawer
        anchor={"left"}
        open={state["left"]}
        onClose={ToggleDrawer("left", false)}
      >
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={ToggleDrawer("left", false)}
          onKeyDown={ToggleDrawer("left", false)}
        >
          <List>
            <ListItem disablePadding>
              <ListItemButton onClick={() => navigate("/")}>
                <ListItemIcon>
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText primary={"Home"} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={() => navigate("/messages")}>
                <ListItemIcon>
                  <HistoryIcon />
                </ListItemIcon>
                <ListItemText primary={"Messages"} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={() => navigate("/setup")}>
                <ListItemIcon>
                  <PhonelinkSetupIcon />
                </ListItemIcon>
                <ListItemText primary={"Setup Cube"} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={Logout}>
                <ListItemIcon>
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary={"Logout"} />
              </ListItemButton>
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem disablePadding>
              <ListItemButton onClick={() => EnableNotifications()}>
                <ListItemIcon>
                  <NotificationAddIcon />
                </ListItemIcon>
                <ListItemText primary={"Enable Notifications"} />
              </ListItemButton>
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem disablePadding>
              <ListItemButton onClick={() => navigate("/about")}>
                <ListItemIcon>
                  <InfoIcon />
                </ListItemIcon>
                <ListItemText primary={"About"} />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </Drawer>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={ToggleDrawer("left", true)}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Friend Cube
            </Typography>
            {user && user.displayName && user.photoURL ? (
              <Avatar alt={user.displayName} src={user.photoURL} />
            ) : (
              <></>
            )}
          </Toolbar>
        </AppBar>
      </Box>
      <ToastContainer
        position="top-center"
        autoClose={5000}
      />
    </>
  );
};